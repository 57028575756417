.container {
  max-width: 1080px;
  margin: auto;
}

.Profile {
  margin: 2rem 0 0;
  padding: 1rem;
  display: flex;
  align-items: stretch;
  flex-flow: column;
}

.Profile__media img {
  flex: 1;
  max-width: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Profile__name {
  padding: 2rem 2rem 0;
}

.Profile__name h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  position: relative;
  padding-left: 1.5rem;
}

.Profile__name h3::before {
  content: "";
  display: block;
  background: #eec11a;
  height: 1.5rem;
  width: .5rem;
  transform: translateX(-50%);
  position: absolute;
  left: 0;
}

.Profile__name h4 {
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin: .5rem 0 0 1.5rem;
}

.Profile__content {
  padding: 2rem 2rem 0;
}

.Profile__detail {
  border-top: 1px solid #c4cdd5;
  padding: 2rem;
}

.Profile__detail h4 {
  margin: 0;
  line-height: 2.4rem;
  /* small caps */
  text-transform: uppercase;
  font-family: Futura,Futura Std,sans-serif;
  font-weight: 700;
  letter-spacing: .275rem;
  font-size: 1.1rem;
}

.Profile__detail p {
  margin: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.Profile__detail ul {
  margin: 0;
  padding: 0;
}

.Profile__detail ul li {
  font-size: 1.5rem;
  line-height: 2.5rem;
  display: inline;
}

.Profile__detail ul li::after {
  content: ", ";
}

.Profile__detail ul li:last-child::after {
  content: "";
}

@media (max-width: 599.5px) {
  .Profile {
    margin: 0 0 2rem;
  }

  .Profile__content {
    padding: 0;
  }

  .Profile__detail:first-child {
    border: none;
  }
}

@media (min-width: 600px) {
  .Profile {
    flex-flow: row wrap;
    padding: 0;
  }

  .Profile__media {
    flex: 0 0 25rem;
    height: 25rem;
    margin-left: 2rem;
  }

  .Profile__name {
    flex: 0 1 30rem;
    padding: 2rem;
  }

  .Profile__content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 100%;
    margin-right: 5rem;
  }

  .Profile__detail {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    padding: .5rem 0 4rem;
  }

  .Profile__detail h4 {
    flex: 0 0 25rem;
    margin-right: 1rem;
  }

  .Profile__detail h4 + * {
    flex: 0 1 auto;
  }

  .Profile__detail ul li, .Profile__detail p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
